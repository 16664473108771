import {React, useEffect, useContext, useState} from 'react';
import './experience.scss';
import '../../App.css';

function ExperienceDetails({position, company, timeline, accolades, url}) {

  return (
    <div className="experience-details">
        <div className="flex-row position-header">
            <span className="experience-details-position">{position}</span>
            <span>-</span>
            <span className="experience-details-company"><a href={url}>{company}</a></span>
        </div>
       
        <span className="experience-details-timeline">{timeline}</span>
        <span className="experience-details-accolades">{accolades}</span>
    </div>
  );
}

export default ExperienceDetails;
