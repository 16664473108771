import logo from './assets/technology_circuit_board_hd_technology-1920x1080.jpg';
import './App.css';
import { Parallax } from 'react-parallax';
import MyNavbar from './components/navbar/navbar';
import ContentContainer from './components/content-container/content-container';
import Layout from './components/layout/layout';
import Welcome from './components/welcome/welcome';
import About from './components/about/about';
import Experience from './components/experience/experience';
const insideStyles = {
  background: "white",
  padding: 20,
  position: "relative",
  width: "200px",
  top: "50%",
  left: "50%",
  //transform: "translate(-50%,-50%)",
  'transition-delay': '100ms',
  // easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
  // transform: 'translateX("100vw")',
};

function App() {
  return (
    <Layout>
      <MyNavbar></MyNavbar>
      <ContentContainer style={{backgroundImage: `url(/assets/technology_circuit_board_hd_technology-1920x1080.jpg)`}}>
        <Welcome />
      </ContentContainer>
      <ContentContainer>
        <About />
      </ContentContainer>
      <ContentContainer>
        <Experience />
      </ContentContainer>
    </Layout>
  );
}

export default App;
