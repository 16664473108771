import './navbar.scss';

const navItems = [
  'About Me',
  'Experience',
  'Projects',
]
function MyNavbar() {

  const getFadeInStyle = (index) => {
    return {
      top: '-100px',
      position: 'relative',
      animation: 'fadeInAnimation ease 1s',
      animationIterationCount: 1,
      animationFillMode: 'forwards',
      animationDelay: `${400*(index)}ms`
    }
  }
  return (
    <div className="my-navbar">
      {
        navItems.map((item, index) => {
          return <div className="nav-item" href="#home" style={getFadeInStyle(index)}>{item}</div>
        })
      }
    </div>
  );
}

export default MyNavbar;
