import './content-container.scss';

const navItems = [
  'About Me',
  'Experience',
  'Projects',
]
function ContentContainer({children}) {
  return (
    <div className="content-container">
        {children}
    </div>
  );
}

export default ContentContainer;
