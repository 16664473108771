import './welcome.scss';

function Welcome({children}) {
  return (
    <div className="welcome">
        <span className="welcome-title">Hello! My name is</span>
        <span className="welcome-name">Daniel Schroeder</span>
        <span className="welcome-subheader">I like to code.</span>
    </div>
  );
}

export default Welcome;
