import {React, useEffect, useContext, useState} from 'react';
import './experience.scss';
import experiences from './experiences.json'
import ExperienceDetails from './experience-details';
function Experience({children}) {
    const [active, setActive] = useState(experiences[0])

    

    const setActivExperience = (experience) => {
        setActive(experience)
    }

  return (
    <div className="experience">
        <span className="section-header">Experience</span>
        <div className="content-switcher">
            <div className='tab-container'>
                {
                    experiences.map((e) => {
                        return <span className={active === e ? 'active' : ''} onClick={() => setActivExperience(e)} >{e.company}</span>
                    })
                }
            </div>

            <ExperienceDetails
                company={active.company}
                position={active.position}
                timeline={active.timeline}
                accolades={active.accolades}
                url={active.url}
            />


        </div>
    </div>
  );
}

export default Experience;
