import './about.scss';

function About({children}) {
  return (
    <div className="about">
        <span className="section-header">About Me</span>
        <p className="about-info">
            Welcome to my brain.
        </p>
        <p className="about-info">
            Daniel is the name, App Development is the game. Since taking an intro to computer science class in high school,
            I knew what I wanted to do for the rest of my career.
            <br/>
            There was something so satisfying about seeing applications come to life, solving problems from scratch,
            and having unlimited choices to make every step of the way.
        </p>
        <p className="about-info">
            I currently work at <a href='https://www.flexe.com/'>Flexe</a>, creating leading-edge WMS software for
            supply chain optimization and flexible warehouse infrastructure. They call me the "Doc Daddy" - specializing in Document
            Templatization and Generation, Vendor Compliance, and User Efficiency.
        </p>
    </div>
  );
}

export default About;
